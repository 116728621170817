import {
  getApiService,
  ResponseBase,
  DEFAULT_API_PARAMS,
} from "@/api/api.service";
import { AxiosInstance, AxiosResponse } from "axios";
import {
  EstimationData,
  formSelectOptions,
  Product,
} from "@/views/order-form/order-form-interfaces";
import {
  SelectPlanFormData,
  SelectPlanOption,
} from "@/views/order-form/select-plan/select-plan-interfaces";
import { CustomerInfoFormData } from "@/views/order-form/customer-info/customer-info-interfaces";

export interface GetFormOptionsResponse extends ResponseBase {
  data?: formSelectOptions;
}

export interface GetProductListsResponse extends ResponseBase {
  data?: Product[];
}

export interface GetEstimationResponse extends ResponseBase {
  data?: EstimationData;
}

export interface GetInStockResponse extends ResponseBase {
  data?: {
    zaiko_flag: string;
  };
}

export interface CreateOrderResponse extends ResponseBase {
  data?: {
    order_no: string;
  };
}

export interface getProductParams {
  country_name1: string;
  country_name2?: string;
  country_name3?: string;
  country_name4?: string;
}

export interface GetEstimationParams {
  country_name1: string;
  country_name2?: string;
  country_name3?: string;
  country_name4?: string;
  order_from_date: string;
  order_to_date: string;
  order_get_type: string;
  order_send_pref_id: string;
  order_return_type: string;
  order_pro_name_id: number;
  order_pro_qty: number;
  order_pro_insurance: string;
  order_option?: OrderOption[];
  order_shop_code?: string;
  order_camp_code?: string;
  order_pay_method?: string;
}

export interface OrderOption {
  order_option_name_id: number;
  order_option_qty: number;
}

export interface GetInStockParams {
  country_name1: string;
  country_name2?: string;
  country_name3?: string;
  country_name4?: string;
  order_from_date: string;
  order_to_date: string;
  order_get_type: string;
  order_send_pref_id: string;
  order_return_type: string;
  order_pro_name_id: number;
  order_pro_qty: number;
  order_pro_insurance: string;
  order_option: OrderOption[];
  order_shop_code?: string;
  order_camp_code?: string;
}

export interface CreateOrderParams {
  country_name1: string;
  country_name2?: string;
  country_name3?: string;
  country_name4?: string;
  order_from_date: string;
  order_to_date: string;
  order_get_type: string;
  order_send_pref_id: string;
  order_return_type: string;
  order_pro_name_id: number;
  order_pro_qty: number;
  order_pro_insurance: string;
  order_option: OrderOption[];
  order_shop_code?: string;
  order_camp_code?: string;
  order_pay_method?: string;
  order_membercd?: number;
  order_member_token?: string;
  order_company?: string;
  order_department?: string;
  order_name: string;
  order_name2: string;
  order_gnm1: string;
  order_gnm2: string;
  order_birthday: string;
  order_sex: string;
  order_postcode: string;
  order_shire_id: string;
  order_address1: string;
  order_address2: string;
  order_address3?: string;
  order_phone: string;
  order_tel?: string;
  order_email: string;
  order_ana_mileage_no: string;
  order_receipt_name?: string;
  order_pwd?: string;
  order_use_name: string;
  order_use_name2: string;
  order_use_gnm1: string;
  order_use_gnm2: string;
  order_send_company?: string;
  order_send_department?: string;
  order_send_name?: string;
  order_send_name2?: string;
  order_send_gnm1?: string;
  order_send_gnm2?: string;
  order_send_postcode?: string;
  order_send_shire_id?: string;
  order_send_address1?: string;
  order_send_address2?: string;
  order_send_address3?: string;
  order_send_phone?: string;
  order_credit_token: string;
}

class OrderService {
  axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = getApiService();
  }

  getFormOptions(): Promise<AxiosResponse<GetFormOptionsResponse>> {
    return this.axiosInstance.post<GetFormOptionsResponse>(
      "getSystemMaster",
      DEFAULT_API_PARAMS
    );
  }

  getProductLists(
    params: getProductParams
  ): Promise<AxiosResponse<GetProductListsResponse>> {
    return this.axiosInstance.post<GetProductListsResponse>("getProduct", {
      ...params,
      ...DEFAULT_API_PARAMS,
    });
  }

  getEstimation(
    params: GetEstimationParams
  ): Promise<AxiosResponse<GetEstimationResponse>> {
    return this.axiosInstance.post<GetEstimationResponse>("calculateMitumori", {
      ...params,
      ...DEFAULT_API_PARAMS,
    });
  }

  getInStockStatus(
    params: GetInStockParams
  ): Promise<AxiosResponse<GetInStockResponse>> {
    return this.axiosInstance.post<GetInStockResponse>("checkZaiko", {
      ...params,
      ...DEFAULT_API_PARAMS,
    });
  }

  createOrder(
    params: CreateOrderParams
  ): Promise<AxiosResponse<CreateOrderResponse>> {
    return this.axiosInstance.post<CreateOrderResponse>("createOrder", {
      ...params,
      ...DEFAULT_API_PARAMS,
    });
  }
}

export function prepareCountryParams(countries: string[]): getProductParams {
  const max = 4; // TODO: 共通化
  const array = countries.slice(0, max).map((country, index) => {
    return ["country_name" + (index + 1), country];
  });
  return Object.fromEntries(array);
}

export function prepareOptionParams(
  options: SelectPlanOption[]
): OrderOption[] {
  return options.map((option) => {
    return {
      order_option_name_id: Number(option.order_option_name_id),
      order_option_qty: Number(option.order_option_qty),
    };
  });
}

export function prepareEstimationParams(
  data: SelectPlanFormData
): GetEstimationParams {
  const options = prepareOptionParams(data.order_option);
  const params: GetEstimationParams = {
    country_name1: "",
    country_name2: "",
    country_name3: "",
    country_name4: "",
    order_from_date: data.order_from_date,
    order_to_date: data.order_to_date,
    order_get_type: data.order_get_type,
    order_send_pref_id: data.order_send_pref_id,
    order_return_type: data.order_return_type,
    order_pro_name_id: Number(data.order_pro_name_id),
    order_pro_qty: Number(data.order_pro_qty),
    order_option: options,
    order_pro_insurance: data.order_pro_insurance,
    order_shop_code: data.order_shop_code,
    order_camp_code: data.order_camp_code,
    order_pay_method: data.order_pay_method,
  };
  const countries = prepareCountryParams(data.countries);
  return { ...params, ...countries };
}

export function prepareCreateOrderParams(
  selectPlanData: SelectPlanFormData,
  customerInfoData: CustomerInfoFormData
): CreateOrderParams {
  const countries = prepareCountryParams(selectPlanData.countries);
  const options = prepareOptionParams(selectPlanData.order_option);
  const params: CreateOrderParams = {
    country_name1: "",
    country_name2: "",
    country_name3: "",
    country_name4: "",
    order_from_date: selectPlanData.order_from_date,
    order_to_date: selectPlanData.order_to_date,
    order_get_type: selectPlanData.order_get_type,
    order_send_pref_id: selectPlanData.order_send_pref_id,
    order_return_type: selectPlanData.order_return_type,
    order_pro_name_id: Number(selectPlanData.order_pro_name_id),
    order_pro_qty: Number(selectPlanData.order_pro_qty),
    order_pro_insurance: selectPlanData.order_pro_insurance,
    order_option: options,
    order_shop_code: selectPlanData.order_shop_code,
    order_camp_code: selectPlanData.order_camp_code,
    order_pay_method: selectPlanData.order_pay_method,
    order_company: customerInfoData.order_company,
    order_department: customerInfoData.order_department,
    order_name: customerInfoData.order_name,
    order_name2: customerInfoData.order_name2,
    order_gnm1: customerInfoData.order_gnm1,
    order_gnm2: customerInfoData.order_gnm2,
    order_birthday: customerInfoData.order_birthday,
    order_sex: customerInfoData.order_sex,
    order_postcode: customerInfoData.order_postcode,
    order_shire_id: customerInfoData.order_shire_id,
    order_address1: customerInfoData.order_address1,
    order_address2: customerInfoData.order_address2,
    order_address3: customerInfoData.order_address3,
    order_phone: customerInfoData.order_phone,
    order_tel: customerInfoData.order_tel,
    order_email: customerInfoData.order_email,
    order_ana_mileage_no: customerInfoData.order_ana_mileage_no,
    order_receipt_name: customerInfoData.order_receipt_name,
    order_pwd: customerInfoData.order_pwd,
    order_use_name: customerInfoData.order_use_name,
    order_use_name2: customerInfoData.order_use_name2,
    order_use_gnm1: customerInfoData.order_use_gnm1,
    order_use_gnm2: customerInfoData.order_use_gnm2,
    order_send_company: customerInfoData.order_send_company,
    order_send_department: customerInfoData.order_send_department,
    order_send_name: customerInfoData.order_send_name,
    order_send_name2: customerInfoData.order_send_name2,
    order_send_gnm1: customerInfoData.order_send_gnm1,
    order_send_gnm2: customerInfoData.order_send_gnm2,
    order_send_postcode: customerInfoData.order_send_postcode,
    order_send_shire_id: customerInfoData.order_send_shire_id,
    order_send_address1: customerInfoData.order_send_address1,
    order_send_address2: customerInfoData.order_send_address2,
    order_send_address3: customerInfoData.order_send_address3,
    order_send_phone: customerInfoData.order_send_phone,
    order_credit_token: customerInfoData.order_credit_token,
  };
  if (customerInfoData.order_membercd) {
    params.order_membercd = Number(customerInfoData.order_membercd);
  }
  if (customerInfoData.order_member_token) {
    params.order_member_token = customerInfoData.order_member_token;
  }
  return { ...params, ...countries };
}

export function prepareGetInStockParams(
  selectPlanData: SelectPlanFormData
): GetInStockParams {
  const countries = prepareCountryParams(selectPlanData.countries);
  const options = prepareOptionParams(selectPlanData.order_option);
  const params: GetInStockParams = {
    country_name1: "",
    country_name2: "",
    country_name3: "",
    country_name4: "",
    order_camp_code: selectPlanData.order_camp_code,
    order_from_date: selectPlanData.order_from_date,
    order_get_type: selectPlanData.order_get_type,
    order_option: options,
    order_pro_insurance: selectPlanData.order_pro_insurance,
    order_pro_name_id: Number(selectPlanData.order_pro_name_id),
    order_pro_qty: Number(selectPlanData.order_pro_qty),
    order_return_type: selectPlanData.order_return_type,
    order_send_pref_id: selectPlanData.order_send_pref_id,
    order_shop_code: selectPlanData.order_shop_code,
    order_to_date: selectPlanData.order_to_date,
  };
  return { ...params, ...countries };
}

export default new OrderService();
