
import { defineComponent } from "vue";

export default defineComponent({
  name: "globalFooter",
  data() {
    return {
      buttonActive: false,
      scroll: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
  },
  methods: {
    returnTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollWindow() {
      const top = 100; // ボタンを表示させたい位置
      this.scroll = window.scrollY;
      if (top <= this.scroll) {
        this.buttonActive = true;
      } else {
        this.buttonActive = false;
      }
    },
  },
});
