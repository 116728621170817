
import { defineComponent } from "vue";
import RatePlan from "@/views/home/component/RatePlan.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    RatePlan,
  },
});
