
import { defineComponent } from "vue";
import { useGlobalStore } from "@/stores/global";

export default defineComponent({
  name: "OnLoading",
  computed: {
    globalState() {
      return useGlobalStore();
    },
    isShow(): boolean {
      return this.globalState.$state.views.isLoading;
    },
  },
  emits: ["update:isShow"],
});
