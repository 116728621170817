import {
  getApiService,
  ResponseBase,
  DEFAULT_API_PARAMS,
} from "@/api/api.service";
import { AxiosInstance, AxiosResponse } from "axios";

export interface UserLoginResponse extends ResponseBase {
  data?: {
    member_id: number;
    member_email: string;
    member_company_type: string;
    member_company: string;
    member_department: string;
    member_company_code: string;
    member_company_phone: string;
    member_company_mobile_phone: string;
    member_fnm1: string;
    member_fnm2: string;
    member_birthday: string;
    member_sex: string;
    member_postcode: string;
    member_shire_id: string;
    member_address1: string;
    member_address2: string;
    member_address3: string;
    member_phone: string;
    member_loginconfirm: string;
    member_status: string;
    member_magazine_flg: string;
    member_way_flg: string;
    member_black_flg: string;
    member_send_postcode: string;
    member_send_shire_id: string;
    member_send_address1: string;
    member_send_address2: string;
    member_send_address3: string;
    member_gnm1: string;
    member_gnm2: string;
    member_send_phone: string;
    member_send_company: string;
    member_send_department: string;
    member_token: string;
  };
}

export interface UserLogoutResponse extends ResponseBase {
  data?: [];
}

export interface CheckLoginTokenResponse extends ResponseBase {
  data?: object;
}

export interface UserLoginParams {
  member_email: string;
  member_passwd: string;
}

export interface UserLogoutParams {
  member_id: number;
  member_token: string;
}

export interface CheckLoginTokenParams {
  member_id: number;
  member_token: string;
}

class LoginService {
  axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = getApiService();
  }

  userLogin(
    params: UserLoginParams
  ): Promise<AxiosResponse<UserLoginResponse>> {
    return this.axiosInstance.post<UserLoginResponse>("userLogin", {
      ...params,
      ...DEFAULT_API_PARAMS,
    });
  }

  userLogout(
    params: UserLogoutParams
  ): Promise<AxiosResponse<UserLogoutResponse>> {
    return this.axiosInstance.post<UserLogoutResponse>("userLogout", {
      ...params,
      ...DEFAULT_API_PARAMS,
    });
  }

  checkLoginToken(
    params: CheckLoginTokenParams
  ): Promise<AxiosResponse<CheckLoginTokenResponse>> {
    return this.axiosInstance.post<CheckLoginTokenResponse>("checkLoginToken", {
      ...params,
      ...DEFAULT_API_PARAMS,
    });
  }
}

export default new LoginService();
