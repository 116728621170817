import {
  NestedSelectOptions,
  SelectOptions,
} from "@/views/shared/form-parts/PullDownSelect.vue";
import {
  AreaList,
  CountryList,
  PrefData,
  ShireList,
} from "@/views/order-form/order-form-interfaces";

export default {
  isEmptyObject(obj: object): boolean {
    return !Object.keys(obj).length;
  },
  dateFormat(date: string | Date): string {
    if (!date) return "";
    const dateInstance = new Date(date);
    const year = dateInstance.getFullYear();
    const month = String(dateInstance.getMonth() + 1).padStart(2, "0");
    const day = String(dateInstance.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  },
  calculateTravelingDays(
    depDate: string | Date,
    ArrDate: string | Date
  ): string {
    const depTime: number = new Date(depDate).getTime();
    const arrTime: number = new Date(ArrDate).getTime();
    const diffTime =
      Math.floor((arrTime - depTime) / (1000 * 60 * 60 * 24)) + 1;
    return String(diffTime);
  },
  scrollPosition(top: number): void {
    const ScrollToOptions: ScrollToOptions = {
      left: 0,
      top: top,
      behavior: "smooth",
    };
    window.scrollTo(ScrollToOptions);
  },
  onScrollTop(): void {
    const ScrollToOptions = {
      left: 0,
      top: 0,
    };
    window.scrollTo(ScrollToOptions);
  },
  scrollToInvalidElement(): void {
    const invalidElm = document.getElementsByClassName("invalid");
    const elmPosition = invalidElm[0].getBoundingClientRect().top;
    const elmOffsetHeight = invalidElm[0].scrollHeight;
    const positionY = window.scrollY + elmPosition - elmOffsetHeight - 100;
    this.scrollPosition(positionY);
  },
  getAreaSelectOptions(areaList: AreaList[]): SelectOptions[] {
    if (areaList.length === 0) return [];
    return areaList.map((area: AreaList): SelectOptions => {
      return {
        label: area.area_name,
        value: area.area_cd,
      };
    });
  },
  getFilteredCountryOptions(
    areaName: string,
    countryList: CountryList[]
  ): SelectOptions[] {
    if (!areaName || countryList.length === 0) return [];
    const filteredList: CountryList[] = countryList.filter(
      (elm) => elm.country_area_cd === areaName
    );

    if (filteredList.length === 0) return [{ label: "", value: "" }];
    return filteredList.map((country: CountryList): SelectOptions => {
      return {
        label: country.country_name,
        value: country.country_name,
      };
    });
  },
  prepareCardExpirationMonths(): SelectOptions[] {
    return Array(12)
      .fill({})
      .map((elm, index): SelectOptions => {
        return {
          label: index + 1 + "月",
          value: ("0" + (index + 1)).slice(-2),
        };
      });
  },
  prepareCardExpirationYear(thisYear: string | Date): SelectOptions[] {
    const date: Date = new Date(thisYear);
    const year = date.getFullYear();
    return Array(10)
      .fill({})
      .map((elm, index): SelectOptions => {
        return {
          label: year + index + "年",
          value: String(year + index).slice(2),
        };
      });
  },
  preparePrefectureList(shireList: ShireList): NestedSelectOptions[] {
    return Object.entries(shireList).map(([key, value]) => {
      return {
        label: value.label,
        key: key,
        options: value.prefectures.map((obj: PrefData) => {
          return {
            label: obj.shire_name,
            value: obj.shire_id,
          };
        }),
      };
    });
  },
};
