export interface MultiplePlans {
  [key: string]: PlanList[];
}

export interface PlanList {
  label: string;
  coverage: string[] | AreaSegmentedCountries[];
  termLabel: string;
  capacity: string;
  price: number;
  band: string;
}

export interface AreaSegmentedCountries {
  label: string;
  countries: string[];
}

export const COVERAGE = {
  worldPack: [
    {
      label: "北米・ハワイ・グアム",
      countries: [
        "アメリカ",
        "ハワイ",
        "アラスカ",
        "カナダ",
        "グアム",
        "サイパン",
        "テニアン",
        "ロタ",
      ],
    },
    {
      label: "東アジア",
      countries: ["韓国", "台湾", "中国(プレミアム回線)", "香港", "マカオ"],
    },
    {
      label: "東南アジア",
      countries: [
        "インドネシア",
        "カンボジア",
        "シンガポール",
        "タイ",
        "フィリピン",
        "ベトナム",
        "マレーシア",
        "ミャンマー",
        "ラオス",
      ],
    },
    {
      label: "オセアニア",
      countries: ["オーストラリア(豪州)", "ニュージーランド", "フィジー諸島"],
    },
    {
      label: "ヨーロッパ/ロシア",
      countries: [
        "フランス",
        "イタリア",
        "イギリス",
        "スペイン",
        "ドイツ",
        "アイスランド",
        "アイルランド",
        "アルバニア",
        "イギリス領マン島",
        "ウクライナ",
        "エストニア",
        "オーストリア(EU)",
        "オランダ",
        "オーランド諸島",
        "キプロス",
        "ギリシャ",
        "クロアチア",
        "サンマリノ",
        "ジブラルタル",
        "ジャージー島",
        "スイス",
        "スウェーデン",
        "スロベニア",
        "スロバキア",
        "セルビア共和国",
        "チェコ共和国",
        "デンマーク",
        "トルコ",
        "ノルウェー",
        "バチカン市国",
        "ハンガリー",
        "フィンランド",
        "ブルガリア",
        "ベルギー",
        "ポーランド",
        "ポルトガル",
        "マルタ",
        "モナコ",
        "モンテネグロ共和国",
        "ラトビア",
        "リトアニア",
        "リヒテンシュタイン",
        "ルーマニア",
        "ルクセンブルク",
        "ロシア",
      ],
    },
    {
      label: "その他アジア/中東",
      countries: [
        "アラブ首長国連邦",
        "インド",
        "イスラエル",
        "オマーン",
        "カタール",
        "サウジアラビア",
        "スリランカ",
        "ネパール",
        "パキスタン",
        "バーレーン",
        "バングラディッシュ",
        "モンゴル",
        "ヨルダン",
      ],
    },
    {
      label: "中南米",
      countries: [
        "メキシコ",
        "ブラジル",
        "アルゼンチン",
        "ウルグアイ",
        "コロンビア",
        "チリ",
        "ドミニカ共和国",
        "パナマ",
        "プエルトリ",
        "ベネズエラ",
        "ペルー",
      ],
    },
    {
      label: "アフリカ",
      countries: [
        "南アフリカ",
        "エジプト",
        "ケニア",
        "ザンビア",
        "ナイジェリア",
        "西サハラ",
        "マダガスカル",
        "モロッコ",
        "モーリシャス",
      ],
    },
  ],
  worldUnLimit: [
    {
      label: "北米・ハワイ・グアム",
      countries: [
        "アメリカ",
        "ハワイ",
        "アラスカ",
        "カナダ",
        "グアム",
        "サイパン",
        "テニアン",
        "ロタ",
      ],
    },
    {
      label: "東アジア",
      countries: ["韓国", "台湾", "中国(プレミアム回線)", "香港", "マカオ"],
    },
    {
      label: "東南アジア",
      countries: [
        "インドネシア",
        "カンボジア",
        "シンガポール",
        "タイ",
        "フィリピン",
        "ベトナム",
        "マレーシア",
        "ミャンマー",
        "ラオス",
      ],
    },
    {
      label: "オセアニア",
      countries: ["オーストラリア(豪州)", "ニュージーランド"],
    },
    {
      label: "ヨーロッパ/ロシア",
      countries: [
        "フランス",
        "イタリア",
        "イギリス",
        "スペイン",
        "ドイツ",
        "アイスランド",
        "アイルランド",
        "ウクライナ",
        "エストニア",
        "オーストリア(EU)",
        "オランダ",
        "オーランド諸島",
        "キプロス",
        "ギリシャ",
        "クロアチア",
        "サンマリノ",
        "ジブラルタル",
        "スイス",
        "スウェーデン",
        "スロベニア",
        "スロバキア",
        "セルビア共和国",
        "チェコ共和国",
        "デンマーク",
        "トルコ",
        "ノルウェー",
        "バチカン市国",
        "ハンガリー",
        "フィンランド",
        "ブルガリア",
        "ベルギー",
        "ポーランド",
        "ポルトガル",
        "マルタ",
        "モナコ",
        "ラトビア",
        "リトアニア",
        "リヒテンシュタイン",
        "ルーマニア",
        "ルクセンブルク",
        "ロシア",
      ],
    },
    {
      label: "その他アジア/中東",
      countries: [
        "アラブ首長国連邦",
        "インド",
        "イスラエル",
        "オマーン",
        "カザフスタン",
        "カタール",
        "サウジアラビア",
        "スリランカ",
        "ネパール",
        "パキスタン",
        "バーレーン",
        "バングラディッシュ",
        "モンゴル",
        "ヨルダン",
      ],
    },
    {
      label: "中南米",
      countries: [
        "メキシコ",
        "ブラジル",
        "ウルグアイ",
        "コロンビア",
        "チリ",
        "パナマ",
        "プエルトリ",
        "ベネズエラ",
        "ペルー",
      ],
    },
    {
      label: "アフリカ",
      countries: [
        "南アフリカ",
        "エジプト",
        "ケニア",
        "モロッコ",
        "モーリシャス",
      ],
    },
  ],
  worldLimited: [
    {
      label: "北米・ハワイ・グアム",
      countries: [
        "アメリカ",
        "ハワイ",
        "アラスカ",
        "カナダ",
        "グアム",
        "サイパン",
        "テニアン",
        "ロタ",
      ],
    },
    {
      label: "東アジア",
      countries: ["韓国", "台湾", "中国(プレミアム回線)", "香港", "マカオ"],
    },
    {
      label: "東南アジア",
      countries: [
        "インドネシア",
        "カンボジア",
        "シンガポール",
        "タイ",
        "フィリピン",
        "ベトナム",
        "マレーシア",
        "ミャンマー",
        "ラオス",
        "ブルネイ",
      ],
    },
    {
      label: "オセアニア",
      countries: ["オーストラリア(豪州)", "ニュージーランド", "フィジー諸島"],
    },
    {
      label: "ヨーロッパ/ロシア",
      countries: [
        "フランス",
        "イタリア",
        "イギリス",
        "スペイン",
        "ドイツ",
        "アイスランド",
        "アイルランド",
        "アルバニア",
        "イギリス領ガーンジー島",
        "イギリス領マン島",
        "ウクライナ",
        "エストニア",
        "オーストリア(EU)",
        "オランダ",
        "オーランド諸島",
        "北マケドニア共和国",
        "キプロス",
        "ギリシャ",
        "クロアチア",
        "サンマリノ",
        "ジブラルタル",
        "ジャージー島",
        "スイス",
        "スウェーデン",
        "スロベニア",
        "スロバキア",
        "セルビア共和国",
        "チェコ共和国",
        "デンマーク",
        "トルコ",
        "ノルウェー",
        "バチカン市国",
        "ハンガリー",
        "フィンランド",
        "ブルガリア",
        "ベルギー",
        "ボスニア・ヘルツェゴヴィナ",
        "ポーランド",
        "ポルトガル",
        "マルタ",
        "モナコ",
        "モンテネグロ共和国",
        "ラトビア",
        "リトアニア",
        "リヒテンシュタイン",
        "ルーマニア",
        "ルクセンブルク",
        "ロシア",
      ],
    },
    {
      label: "その他アジア/中東",
      countries: [
        "アラブ首長国連邦",
        "インド",
        "イスラエル",
        "オマーン",
        "カザフスタン",
        "カタール",
        "クウェート",
        "サウジアラビア",
        "スリランカ",
        "ネパール",
        "パキスタン",
        "バーレーン",
        "バングラディッシュ",
        "モルディブ",
        "モンゴル",
        "ヨルダン",
      ],
    },
    {
      label: "中南米",
      countries: [
        "メキシコ",
        "ブラジル",
        "アルゼンチン",
        "アルバ",
        "アンティグア・バーブーダ",
        "イギリス領ヴァージン諸島",
        "ウルグアイ",
        "エクアドル",
        "エルサルバドル",
        "ガイアナ",
        "グアテマラ",
        "グアドループ",
        "グレナダ",
        "ケイマン諸島",
        "コスタリカ",
        "コロンビア",
        "ジャマイカ",
        "スリナム",
        "セントビンセント・グレナディーン",
        "セント・マーチン島",
        "タークス・カイコス諸島",
        "チリ",
        "ドミニカ共和国",
        "ニカラグア",
        "ハイチ",
        "パナマ",
        "プエルトリコ",
        "ベネズエラ",
        "ペルー",
        "ボリビア",
        "マルティニーク",
      ],
    },
    {
      label: "アフリカ",
      countries: [
        "南アフリカ",
        "エジプト",
        "ケニア",
        "ザンビア",
        "タンザニア",
        "ナイジェリア",
        "西サハラ",
        "マダガスカル",
        "モロッコ",
        "モーリシャス",
      ],
    },
  ],
  europe: [
    "フランス",
    "イタリア",
    "イギリス",
    "スペイン",
    "ドイツ",
    "アイスランド",
    "アイルランド",
    "ウクライナ",
    "エストニア",
    "オーストリア(EU)",
    "オランダ",
    "ギリシャ",
    "クロアチア",
    "サンマリノ",
    "スイス",
    "スウェーデン",
    "スロバキア",
    "チェコ共和国",
    "デンマーク",
    "トルコ",
    "ノルウェー",
    "ハンガリー",
    "フィンランド",
    "ブルガリア",
    "ベルギー",
    "ポーランド",
    "ポルトガル",
    "マルタ",
    "モナコ",
    "ラトビア",
    "リトアニア",
    "ルーマニア",
    "ルクセンブルク",
    "ロシア",
  ],
  asia: [
    "韓国",
    "台湾",
    "中国(プレミアム回線)",
    "香港",
    "マカオ",
    "インドネシア",
    "カンボジア",
    "シンガポール",
    "タイ",
    "フィリピン",
    "ベトナム",
    "マレーシア",
    "ミャンマー",
    "ラオス",
    "インド",
  ],
  northAmerica: ["アメリカ", "カナダ", "メキシコ", "アラスカ", "ハワイ"],
};

export const MULTIPLE_PLANS: MultiplePlans = {
  europe: [
    {
      label: "欧州周遊WiFi(4G) 通常プラン",
      coverage: COVERAGE.europe,
      termLabel: "日",
      capacity: "300MB",
      price: 1180,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 大容量プラン",
      coverage: COVERAGE.europe,
      termLabel: "日",
      capacity: "600MB",
      price: 1280,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) ギガプラン",
      coverage: COVERAGE.europe,
      termLabel: "日",
      capacity: "1.1GB",
      price: 1580,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 無制限プラン",
      coverage: COVERAGE.europe,
      termLabel: "日",
      capacity: "無制限",
      price: 1980,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.europe,
      termLabel: "10日間",
      capacity: "2GB",
      price: 3900,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.europe,
      termLabel: "30日間",
      capacity: "5GB",
      price: 8700,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.europe,
      termLabel: "30日間",
      capacity: "20GB",
      price: 22800,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.europe,
      termLabel: "60日間",
      capacity: "10GB",
      price: 13800,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.europe,
      termLabel: "60日間",
      capacity: "30GB",
      price: 33300,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.europe,
      termLabel: "120日間",
      capacity: "40GB",
      price: 46800,
      band: "4G",
    },
    {
      label: "欧州周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.europe,
      termLabel: "180日間",
      capacity: "50GB",
      price: 59400,
      band: "4G",
    },
  ],
  asia: [
    {
      label: "アジア周遊WiFi(4G) 通常プラン",
      coverage: COVERAGE.asia,
      termLabel: "日",
      capacity: "300MB",
      price: 1080,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 大容量プラン",
      coverage: COVERAGE.asia,
      termLabel: "日",
      capacity: "600MB",
      price: 1180,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) ギガプラン",
      coverage: COVERAGE.asia,
      termLabel: "日",
      capacity: "1.1GB",
      price: 1480,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 無制限プラン",
      coverage: COVERAGE.asia,
      termLabel: "日",
      capacity: "無制限",
      price: 1780,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.asia,
      termLabel: "10日間",
      capacity: "2GB",
      price: 3600,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.asia,
      termLabel: "30日間",
      capacity: "5GB",
      price: 7800,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.asia,
      termLabel: "30日間",
      capacity: "20GB",
      price: 19800,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.asia,
      termLabel: "60日間",
      capacity: "10GB",
      price: 12600,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.asia,
      termLabel: "60日間",
      capacity: "30GB",
      price: 29700,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.asia,
      termLabel: "120日間",
      capacity: "40GB",
      price: 41400,
      band: "4G",
    },
    {
      label: "アジア周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.asia,
      termLabel: "180日間",
      capacity: "50GB",
      price: 53100,
      band: "4G",
    },
  ],
  world: [
    {
      label: "世界周遊WiFi(4G) 通常プラン",
      coverage: COVERAGE.worldLimited,
      termLabel: "日",
      capacity: "300MB",
      price: 1780,
      band: "4G",
    },
    {
      label: "世界周遊WiFi(4G) 大容量プラン",
      coverage: COVERAGE.worldLimited,
      termLabel: "日",
      capacity: "600MB",
      price: 1980,
      band: "4G",
    },
    {
      label: "世界周遊WiFi(4G) ギガプラン",
      coverage: COVERAGE.worldLimited,
      termLabel: "日",
      capacity: "1.1GB",
      price: 2480,
      band: "4G",
    },
    {
      label: "世界周遊WiFi(4G) 無制限プラン",
      coverage: COVERAGE.worldUnLimit,
      termLabel: "日",
      capacity: "無制限",
      price: 2980,
      band: "4G",
    },
    {
      label: "世界周遊セレクトWiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.worldPack,
      termLabel: "10日間",
      capacity: "2GB",
      price: 5900,
      band: "4G",
    },
    {
      label: "世界周遊セレクトWiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.worldPack,
      termLabel: "30日間",
      capacity: "5GB",
      price: 12900,
      band: "4G",
    },
    {
      label: "世界周遊セレクトWiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.worldPack,
      termLabel: "30日間",
      capacity: "20GB",
      price: 39900,
      band: "4G",
    },
    {
      label: "世界周遊セレクトWiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.worldPack,
      termLabel: "60日間",
      capacity: "10GB",
      price: 22800,
      band: "4G",
    },
    {
      label: "世界周遊セレクトWiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.worldPack,
      termLabel: "60日間",
      capacity: "30GB",
      price: 59400,
      band: "4G",
    },
    {
      label: "世界周遊セレクトWiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.worldPack,
      termLabel: "120日間",
      capacity: "40GB",
      price: 89400,
      band: "4G",
    },
    {
      label: "世界周遊セレクトWiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.worldPack,
      termLabel: "180日間",
      capacity: "50GB",
      price: 119700,
      band: "4G",
    },
  ],
  northAmerica: [
    {
      label: "北米周遊WiFi(4G) 通常プラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "日",
      capacity: "300MB",
      price: 1180,
      band: "4G",
    },

    {
      label: "北米周遊WiFi(4G) 大容量プラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "日",
      capacity: "600MB",
      price: 1280,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) ギガプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "日",
      capacity: "1.1GB",
      price: 1480,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 無制限プラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "日",
      capacity: "無制限",
      price: 1780,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "10日間",
      capacity: "2GB",
      price: 4200,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "30日間",
      capacity: "5GB",
      price: 9300,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "30日間",
      capacity: "20GB",
      price: 25200,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "60日間",
      capacity: "10GB",
      price: 15300,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "60日間",
      capacity: "30GB",
      price: 36600,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "120日間",
      capacity: "40GB",
      price: 49800,
      band: "4G",
    },
    {
      label: "北米周遊WiFi(4G) 期間内使い切りプラン",
      coverage: COVERAGE.northAmerica,
      termLabel: "180日間",
      capacity: "50GB",
      price: 64800,
      band: "4G",
    },
  ],
};
