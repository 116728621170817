import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b04ac74"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "disabled"]
const _hoisted_2 = {
  key: 0,
  hidden: "hidden",
  value: ""
}
const _hoisted_3 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
    id: _ctx.name,
    name: _ctx.name,
    disabled: _ctx.disabled,
    class: _normalizeClass({ disabled: _ctx.disabled })
  }, [
    (_ctx.placeHolder)
      ? (_openBlock(), _createElementBlock("option", _hoisted_2, _toDisplayString(_ctx.placeHolder), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        value: option.value,
        key: option.value,
        selected: option.value === _ctx.modelValue
      }, _toDisplayString(option.label), 9, _hoisted_3))
    }), 128))
  ], 42, _hoisted_1))
}