import { _GettersTree, defineStore } from "pinia";

export interface GlobalState {
  loginStatus: {
    isLoggedIn: boolean;
  };
  views: {
    isLoading: boolean;
    isMobileMenuShown: boolean;
  };
  couponCodes: {
    campCode: string;
    agencyCode: string;
  };
}

export interface GlobalStateActions {
  logIn: () => void;
  logOut: () => void;
  showLoading: () => void;
  hideLoading: () => void;
  onShowSlideMenu: () => void;
  onHideSlideMenu: () => void;
  setCampaignCode: (code: string) => void;
  setAgencyCode: (code: string) => void;
}

export const useGlobalStore = defineStore<
  string,
  GlobalState,
  _GettersTree<object>,
  GlobalStateActions
>({
  id: "globalState",
  state() {
    return {
      loginStatus: {
        isLoggedIn: false,
      },
      views: {
        isLoading: false,
        isMobileMenuShown: false,
      },
      couponCodes: {
        campCode: "",
        agencyCode: "",
      },
    };
  },
  actions: {
    logIn(): void {
      this.loginStatus.isLoggedIn = true;
    },
    logOut(): void {
      this.loginStatus.isLoggedIn = false;
    },
    showLoading(): void {
      this.views.isLoading = true;
    },
    hideLoading(): void {
      this.views.isLoading = false;
    },
    onShowSlideMenu(): void {
      this.views.isMobileMenuShown = true;
    },
    onHideSlideMenu(): void {
      this.views.isMobileMenuShown = false;
    },
    setCampaignCode(code: string): void {
      this.couponCodes.campCode = code;
    },
    setAgencyCode(code: string): void {
      this.couponCodes.agencyCode = code;
    },
  },
});
