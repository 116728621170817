
import { defineComponent } from "vue";
import { SelectOptions } from "@/views/shared/form-parts/PullDownSelect.vue";

export default defineComponent({
  name: "PullDownSelect",
  props: {
    options: {
      type: [Array as () => SelectOptions[]],
      default: () => [{ label: "", value: "" }],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
      default: "選択してください",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  methods: {
    onChange(event: Event): void {
      this.$emit("change", event);
      if (event.target instanceof HTMLSelectElement) {
        this.$emit("update:modelValue", event.target.value);
      }
    },
  },
  emits: ["change", "update:modelValue"],
});
