import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_header = _resolveComponent("global-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_global_footer = _resolveComponent("global-footer")!
  const _component_on_loading = _resolveComponent("on-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_global_header),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_global_footer),
    _createVNode(_component_on_loading)
  ], 64))
}