
import { defineComponent } from "vue";

export default defineComponent({
  name: "globalHeader",
  data() {
    return {
      width: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize(): void {
      this.width = Number(window.innerWidth);
    },
  },
  computed: {
    isMobile(): boolean {
      return this.width < 1000;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
});
